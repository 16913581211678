.App {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    color: white;
}

.App-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
}

@media (min-width: 650px ) {
    .App {
        background-position: center center;
    }

    .App-content {
        align-items: flex-end;
        justify-content: center;
        color: white;
    }
}
